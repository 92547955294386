import { serializeSample } from "../data-type";

function updateCrc(crc, byte) {
    let output = crc
    output ^= byte;
    for (let i = 0; i < 8; ++i) {
        if (output & 0x1)
            output = (output >> 1) ^ 0xA001;
        else
            output = (output >> 1);
    }

    return output;
}




function computeCrc(sample, config = {}) {
    const ab = new Uint8Array(serializeSample(sample))
    let crc16 = 0
    for (const byte of ab)
        crc16 = updateCrc(crc16, byte)
    const crc = new Uint16Array([crc16]).buffer
    const bytes = new Uint8Array(crc)
    const swappedCrc = new Uint8Array(2)

    // (swap endianess)
    swappedCrc.set(bytes.slice(1, 2))
    swappedCrc.set(bytes.slice(0, 1), 1)
    return swappedCrc;
}


export function encodeCrc(sample) {
    let ab = new Uint8Array(2 + sample.length)
    const crc = computeCrc(sample);
    ab.set(sample)
    ab.set(crc, sample.length)
    return ab
}

export function decodeCrc(sample) {
    const ab = new Uint8Array(sample)

    const sampleCrc = ab.slice(sample.length - 2);


    const payload = sample.slice(0, sample.length - 2)
    const expectedCrc = computeCrc(payload)
    if (payload.length && expectedCrc.toString() === sampleCrc.toString())
        return payload
}