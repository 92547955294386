import { get } from "object-path-immutable"
import * as d3 from 'd3'
const BG_COLOR = 'rgb(0,0,0)'
const POINT_WIDTH = 5
const DEFAULT_TIMESPAN = 30000
const FRAME_PERIOD = 20


export default class DatagramView {
    constructor(cfg) {
        this.config = cfg



        this.t0 = Date.now()
        this.timestamp = this.t0
        this.samples = []


        this.frameRequest = requestAnimationFrame(this.render.bind(this))
    }


    inject(container) {

        const canvas = document.createElement('canvas')

        canvas.style.width = "95%";
        canvas.style.height = "95%";
        container.appendChild(canvas)

        const { clientHeight, clientWidth } = canvas

        const ratio = clientWidth / clientHeight
        const resX = 1000
        const resY = resX / ratio
        canvas.width = resX
        canvas.height = resY
        console.log(ratio, clientHeight, clientWidth, resX, resY)


        this.canvas = canvas
        this.ctx = canvas.getContext('2d')
        const { width, height } = canvas
        this.ctx.fillStyle = BG_COLOR
        this.ctx.fillRect(0, 0, width, height)


        container.style.position = 'relative'

        const t = Date.now()
        const svg = d3.select(container).append("svg")
            .attr('preserveAspectRatio', 'none')
            .style('overflow', 'visible')
            .style('top', 0)
            .style('left', 0)
            .style("width", "95%")
            .style("height", '95%')
            .style("position", 'absolute')
            .attr("viewBox", `0 0 ${resX} ${resY}`)

        const y = d3.scaleBand().domain([0, 1, 2, 3, 4]).range([resY, 0])
        console.log(y(0), y.bandwidth())
        const yAxis = d3.axisRight(y)
        svg.append("g").attr('transform', `translate(${resX}, 0)`).attr("class", "yaxis").call(yAxis)


        const x = d3.scaleTime().domain([t - DEFAULT_TIMESPAN, t]).range([0, resX])
        const xAxis = d3.axisBottom(x).tickFormat(d3.timeFormat("%H:%M:%S"))
        const g = svg.append("g").attr('transform', `translate(0, ${resY})`).attr("class", "xaxis")
        g.call(xAxis)

        this.xAxistransition = (period) => {
            const t = Date.now()
            x.domain([t - DEFAULT_TIMESPAN, t].map(t => t + period))
            g.transition().duration(period).ease(d3.easeLinear).call(xAxis)
        }
        this.xAxistransition()
        const TRANSITION_PERIOD = 5000
        this.interval = setInterval(() => {
            this.xAxistransition(TRANSITION_PERIOD)
        }, TRANSITION_PERIOD)
        this.x = x
        this.y = y
        const domain = this.config.domain || [0, 1]
        console.log(domain)
        this.color = d3.scaleLinear().domain(domain).range(["#000", "#fff"])
        this.render()
    }

    clean() {
        if (this.frameRequest) {
            clearInterval(this.interval)
            this.xAxistransition(0)
            cancelAnimationFrame(this.frameRequest)
        }
    }

    render() {


        const t = Date.now()
        const { timestamp } = this
        const timespan = get(this, ['config', 'timespan'], DEFAULT_TIMESPAN)
        const dt = t - timestamp
        const { ctx } = this
        const { canvas } = ctx
        const { width, height } = canvas
        const dx = POINT_WIDTH;
        this.shift()

        const samples = (this.samples || [])

        // should be abstracted out of DatagramView
        {
            const dy = this.y.bandwidth()
            const xscale = t => width - (timestamp - t) / timespan
            ctx.fillStyle = 'rgb(255,255,255)'
            let nPointsAdded = 0
            for (let sample of samples) {
                if (typeof sample !== 'object') continue;
                const x = xscale(sample._timestamp || t)
                sample.values.forEach((value, index) => {
                    const y = this.y(index)
                    const color = this.color(value)
                    // console.log(value, color)
                    ctx.fillStyle = color
                    ctx.fillRect(x - dx, y, dx, dy)
                    // console.log('draw', x, y)

                    nPointsAdded++
                })
            }
        }

        // if (nPointsAdded)
        //     console.log(Date.now(), 'draw', nPointsAdded, 'points')

        const n = samples.length
        if (false)
            if (n) {

                const x = width - dx
                // ctx.fillStyle = 'rgb(0,0,0)'
                // ctx.fillRect(0, 0, width, height)

                const mean = samples.map(s => s.value).reduce((prev, cur) => cur / n + prev, 0)
                if (!Number.isFinite(mean)) {
                    const error = 'invalid value'
                    state.error = error
                    throw error
                }
                ctx.fillStyle = 'rgb(255,255,255)'
                ctx.fillRect(x, scale(mean), dx, scale(0))
            }
        this.samples = []
        if (!this.stopped)
            this.frameRequest = requestAnimationFrame(this.render.bind(this))


    }



    shift() {
        // console.log("shifting by " + px + "px")
        // warning : timing pas précis ...
        const t = Date.now()
        const { timestamp } = this
        const timespan = get(this, ['config', 'timespan'], DEFAULT_TIMESPAN)
        const dt = t - timestamp
        const { ctx } = this
        const { canvas } = ctx
        const { width, height } = canvas
        const speed = width / timespan //pixel/ms
        const px = -dt * speed + (this.fractionalPx || 0)

        if (px < -1) {
            const intPx = Math.ceil(px)
            this.fractionalPx = px - intPx
            const { ctx } = this
            const { canvas } = ctx
            const { width, height } = canvas

            const data = ctx.getImageData(0, 0, width, height);
            ctx.fillStyle = BG_COLOR
            ctx.fillRect(width + px - POINT_WIDTH + 5, 0, -intPx + POINT_WIDTH, height)
            ctx.putImageData(data, px, 0);
            // requestAnimationFrame(render2)

            this.timestamp += dt
        }
    }


    feed(sample) {
        if (sample === null || sample === undefined) return
        if (!this.error) {
            sample = (typeof sample === 'object') ? sample : { value: sample }
            sample = sample._timestamp ? sample : { ...sample, _timestamp: Date.now() }
            this.samples.push(sample)
        }
    }
}

