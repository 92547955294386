import * as React from 'react';
import { open } from 'web-ui-blocks/itc'
import Transforms from './transforms';
import { knownSources } from '../source';
import { knownViews } from '../view';
import { knownSinks } from '../sink';
import { DEBUG_PROBE, setDebug, toggleLogContainer } from '../global';


export default class ProbeSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { liked: false };
    }
    async edit() {
        const messageHandler = async ({ data }) => {
            try {
                let json
                if (data instanceof Blob || data.toString() === "[object Blob]") {
                    const txt = await data.text()
                    json = JSON.parse(txt)
                } else if (typeof data === 'string') {
                    json = JSON.parse(data)
                } else {
                    json = data
                }
                console.log('receive new spec from editor', json)
                console.log(this.props.update)
                if (json.source)
                    this.props.update([], json)
            } catch (err) {
                console.log('failed to use new data', data)
                console.log(err)
            }


        }
        const disconnectHandler = () => {
            console.log('editor closed')
            channel.removeEventListener("message", messageHandler.bind(this))
            channel.removeEventListener("disconnect", disconnectHandler.bind(this))
            this.channel = null
        }

        const JSON_EDITOR_APP_URL = "https://4np0t3t.gitlab.io/shaky-json/?autoclose"
        const JSON_SCHEMA_URL = "https://4np0t3t.gitlab.io/data-probe/schema.json"

        // should throttled propagate spec update  
        const channel = await open(JSON_EDITOR_APP_URL, "EditorWindow", "_blank");
        const message = {
            $schema: JSON_SCHEMA_URL,
            // "https://public-file-soup.s3-eu-west-1.amazonaws.com/data-probe.schema.json", 
            ...this.props.spec
        }
        this.channel = channel
        console.log("editor opened : sending current spec to it", message)
        channel.send(message)
        channel.addEventListener("message", messageHandler.bind(this))
        channel.addEventListener("disconnected", disconnectHandler.bind(this))
    }




    render(props) {

        const getKnown = (id, list) => {
            const s = list.filter(s => s.id === id)[0]
            if (s)
                return s.value || { type: s.id }

        }
        if (this.state.liked) {
            return 'You liked this.';
        }

        const renderSelectPart = (part, known) => {
            return (
                <section title={JSON.stringify(this.props.spec[part], null, 2)} id={'data-' + part}>
                    <select value={(this.props.spec[part] || {}).type || 'null'} onChange={(e) => this.props.update([part], getKnown(e.target.value, known))} className="type">
                        <option value="null">-</option>
                        {
                            known.map(s => (<option key={s.id} value={s.id}>{s.label || s.id}</option>))
                        }
                    </select>
                </section>
            )
        }
        return (
            <div className="probe-summary row">
                <div className="probe-spec">
                    {renderSelectPart('source', knownSources)}

                    {/* <div>{JSON.stringify(this.props.spec)}</div> */}
                    <Transforms update={trs => this.props.update(['transforms'], trs)} spec={this.props.spec.transforms || []}></Transforms>

                    {renderSelectPart('view', knownViews)}

                    {renderSelectPart('sink', knownSinks)}

                </div>

                <div className="debug">
                    {["SOURCE", "TRANSFORMS", "VIEW", "SINK"].map(key => (<span key={key} >
                        <label>{key}</label>
                        <input type="checkbox" name={key} onChange={e => setDebug(key, !DEBUG_PROBE[key])} />
                    </span>))}
                    <button onClick={toggleLogContainer}>toggle logs</button>
                </div>

            </div>
        )
    }
}