import { set } from "object-path-immutable";
import { fromHexString, toHexString } from "../data-type";
import { DEBUG_PROBE, log } from '../global'
import { decodeCobs, encodeCobs } from "./cobs";
import { decodeCrc, encodeCrc } from "./crc";
import { createSampleFilter } from "./filter";
import { encodeFrame, frameDecoder } from "./frames";
import * as kaitai from './ksy'
import { createSampleMap } from "./map";
const kaitaiTransformers = kaitai.default
const DEFAULT_TRANSFORMS_CONFIG = [
  {
    type: "text",
  },
  {
    type: "lines",
    eol: "\n",
  },
  {
    type: "sv",
    separator: " ",
    columns: "number",
    // columns name,type
    // missing values ?
    // additional values
  },
];

function decodeLayout(bytes, types = [], names = []) {
  let offset = 0,
    i = 0;
  let decoded = {};
  while (offset < bytes.byteLength) {
    let size = 1,
      value,
      name,
      path,
      t,
      typedArray;
    t = types[i % types.length];

    switch (t || "u32") {
      case "i32":
        size = 4;
        typedArray = new Int32Array(bytes.slice(offset, offset + size));
        break;
      case "u32":
        size = 4;
        typedArray = new Uint32Array(bytes.slice(offset, offset + size));
        break;
      case "u64":
        size = 4;
        typedArray = new Uint32Array(bytes.slice(offset, offset + size));
        break;
      case "f64":
        size = 8;
        typedArray = new Float64Array(bytes.slice(offset, offset + size));
        break;
      case "f32":
        size = 4;
        typedArray = new Float32Array(bytes.slice(offset, offset + size));
        break;
      default:
        throw `invalid type: ${t}`;
    }
    value = typedArray[0];
    path = names[i] || ["values", i];
    if (!Array.isArray(path))
      path = [path]
    decoded = set(decoded, path, value)
    offset += size;
    i++;
  }
  return decoded;
}


function createTransform(cfg) {
  let tr = {
    config: cfg,
  };

  switch (cfg.type) {
    case "filter":
      const filterFunction = createSampleFilter(cfg)
      tr.decode = (s) => filterFunction(s)
      break;
    case "map":
      const mapFunction = createSampleMap(cfg)
      tr.decode = (s) => mapFunction(s)
      break;
    case "layout":
      {
        let { types, names } = cfg;
        tr.decode = (bytes) => {
          const decoded = decodeLayout(bytes, types, names);
          return decoded;
        };
      }
      break;
    case 'frames':
      tr.decode = frameDecoder(cfg)
      tr.encode = s => encodeFrame(s, cfg)
      break;
    case 'crc':
      tr.decode = decodeCrc
      tr.encode = encodeCrc
      break;
    case 'cobs':
      tr.decode = s => decodeCobs(s, cfg)
      tr.encode = s => encodeCobs(s, cfg)
      break;
    case "text":
      tr.decoder = new TextDecoder();
      tr.encoder = new TextEncoder();
      tr.decode = (bytes) => {
        const txt = tr.decoder.decode(bytes, { stream: true });
        return txt;
      };
      tr.encode = (txt) => {
        const bytes = tr.encoder.encode(txt);
        return bytes.buffer;
      };
      break;
    case "lines":
      tr.buffer = "";
      tr.decode = (txt) => {
        tr.buffer += txt;
        let lines = tr.buffer.split(tr.config.eol);
        const last = lines.pop();
        tr.buffer = last;
        return lines;
      };
      tr.encode = (txt) => {
        const eol = tr.config.eol;
        let line = txt.endsWith(eol) ? txt : txt + eol;
        return line;
      };
      break;
    case "sv":
      tr.decode = (line) => {
        let values = line
          .split(tr.config.separator)
          .map((w) => w.trim())
          .filter((w) => w)
          .map((w, index) => {
            switch (tr.config.columns) {
              case "number":
                return Number(w);
              default:
                return;
            }
          });
        return { values };
      };
      break;
    case "hex-string":
      tr.decode = (data) => {
        const str = (data || "").toString()
        return fromHexString(str)
      }

      break;
    case 'kaitai':
      tr = { ...tr, ...kaitaiTransformers[cfg.spec || 'dronisos'] }
      break;
    case "hex":
      tr.decode = (arrayBuffer) => {
        if (arrayBuffer instanceof ArrayBuffer)
          return toHexString(Array.from(new Uint8Array(arrayBuffer)));
        else throw "input must be ArrayBuffer";
      };
      tr.encode = (str) => {
        const bytes = fromHexString(str);
        if (bytes) {
          const arrayBuffer = bytes.buffer;
          return arrayBuffer;
        }
      };
      break;
    default:
      throw "invalid transform type: " + cfg.type;
  }
  return tr;
}
export default class DataTransforms {
  constructor(config = DEFAULT_TRANSFORMS_CONFIG) {
    this.config = config;
    this.transforms = [];
    for (let cfg of config) {
      this.transforms.push(createTransform(cfg));
    }
  }
  encode(data) {
    if (data === undefined || data === null) return [];
    if (this.error) return null;
    let current = data;
    let currentTr;
    try {
      for (let tr of this.transforms.slice().reverse()) {
        if (!current)
          return
        currentTr = tr;
        if (!tr.encode) throw "encode not implemented for " + tr.config.type;
        current = tr.encode(current);
        if (DEBUG_PROBE.TRANSFORMS)
          log(current, ["DBG", "TRANSFORMS", "ENCODED", currentTr.config.type.toUpperCase()])
      }
    } catch (err) {
      const errorMessage = `transform "${currentTr.config.type
        }" failed : ${err.toString()}`;
      this.error = err;
      log(errorMessage, ["ERR", "ENCODE"]);
      return [];
    }
    return current;
  }
  decode(data) {
    if (data === undefined || data === null) return [];
    if (this.error) return [];
    let current = Array.isArray(data) ? data : [data];
    let currentTr;
    try {
      for (let tr of this.transforms) {
        if (!tr.decode) throw "decode not implemented for " + tr.config.type;
        currentTr = tr;
        let outputs = [];
        for (let input of current) {
          let transformOutput = tr.decode(input);
          if (transformOutput) {
            if (!Array.isArray(transformOutput))
              transformOutput = [transformOutput];
            outputs = [...outputs, ...transformOutput];
          }
        }
        current = outputs;
        if (DEBUG_PROBE.TRANSFORMS)
          if (current) {
            log(current, ["DBG", "TRANSFORMS", "DECODED", currentTr.config.type.toUpperCase()])
          }
      }
    } catch (err) {
      const errorMessage = `transform "${currentTr.config.type
        }" failed : ${err.toString()}`;

      throw errorMessage;
    }
    return current;
  }
}

