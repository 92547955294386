import * as React from 'react';
import { Component } from "react";
import { getFile, clearFiles } from "../sink/file";
import { download } from 'web-ui-blocks/io'
import { set } from "object-path-immutable";

async function pickFile() {
    return new Promise((res, rej) => {
        var element = document.createElement("input");
        element.setAttribute("type", "file");
        //   element.setAttribute('multiple', "");
        element.onchange = (e) => {
            e.preventDefault();
            res(element.files[0]);
        };

        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    });
}




function renderSize(n = 0) {
    let suffix = 'B'
    if (n > 1000) {
        n /= 1024
        suffix = 'KB'
    }
    if (n > 1000) {
        n /= 1024
        suffix = 'MB'
    }
    if (n > 1000) {
        n /= 1024
        suffix = 'GB'
    }
    return n.toFixed(1) + suffix
}

export default class ProbeControls extends Component {

    async downloadSpec() {
        let { spec } = this.props.store
        spec = set(spec, ["$schema"], 'https://probe.beap.ovh/schema.json')
        let filename = spec.name || spec.title || 'probe'
        filename = filename.toLowerCase().replaceAll(/ /g, "-");
        if (!filename.endsWith('.json'))
            filename += '.json'
        const blob = new Blob([JSON.stringify(spec, null, 2)], { type: "application/json" })
        download(filename, blob)
    }


    async downloadFile(id) {
        console.log(id)
        const file = await getFile(id)
        console.log(file)
        download(id, file)
    }
    async clear() {
        await clearFiles()
    }


    render() {
        const { start, stop } = this.props
        const { status, stats, error, files } = this.props.store || {}
        let renderedStats
        if (stats) {
            const counters = stats
            renderedStats = `${renderSize((counters || {}).bytesFromSource)}(${(counters || {}).samplesFromTransforms})`
        }

        return (

            <section className="controls row">
                <button className="start" onClick={start}> start</button>
                <button className="stop" onClick={stop}>stop</button>



                <div className={['status', status].join(' ')}  title={error ? JSON.stringify(error, null, 2) : null}>{status}</div>

                <div className="column misc" >
                    <div className="probe-stats" >
                        <pre>{stats ? renderedStats : ''}</pre>
                    </div>
                    <div className="row">
                        <button disabled={!files.length} onClick={this.clear}><i className="fas fa-broom"></i></button>
                        <select className="file-download" onChange={e => this.downloadFile(e.target.value)}>
                            <option value="">{'' + files.length + ' files'}</option>
                            {(files || []).map(file => (<option key={file.name} value={file.name}>{file.name} - {renderSize(file.size)}</option>))}
                        </select>
                    </div>

                </div>

            </section>
        )
    }
}