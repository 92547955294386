module.exports = {
    "name": "Binary serial",
    "description": "",
    "source": {
        "type": "serial"
    },
    "transforms": [
        {
            "type": "hex"
        }
    ],
    "view": {
        "type": "console"
    },
    "sink":{
        "type":"file",
        "format": "raw"
    }
};