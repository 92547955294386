import notify from 'web-ui-blocks/notifications'
import DataTransforms from './transforms'
import { ViewBuilders } from './view'
import { SourceBuilders } from './source'
import { SinkBuilders } from './sink'
import { DEBUG_PROBE, log } from './global'
const ALLOWED = [
  ['loading', 'running'],
  ['loading', 'error'],
  ['running', 'error'],
  ['running', 'stopped']
].map(pair => pair.join('-'))
function isAllowedTransition(from, to) {
  if (ALLOWED.indexOf([from, to].join('-')) >= 0)
    return true
  return false
}








function buildPart(input, builders, predefined = []) {
  if (!input) return {};
  let cfg;
  if (typeof cfg == "string") {
    cfg = predefined.filter((item) => item.name == cfg)[0];
    if (!cfg) throw "invalid part name: " + input;
  } else {
    cfg = input;
  }
  const type = cfg.type || "default";
  const builder = builders[type];
  if (builder) {
    return builder(cfg);
  }
}



function mergeParts(parts) {
  let size = 0;
  for (let p of parts) {
    size += p.byteLength;
  }
  const output = new Uint8Array(size);
  let offset = 0;
  for (let p of parts) {
    output.set(new Uint8Array(p), offset);
    offset += p.byteLength;
  }
  return output.buffer;
}

let probeId = 0
export default class DataProbe {
  constructor(config, eventHandler, oldProbe = {}) {
    this.id = ++probeId;
    console.log("new data probe", this.id, config, oldProbe);
    this.config = config;
    this.status = 'loading'; //loading, running, stopped, error
    this.error = null
    this.eventHandler = eventHandler;
    this.oldProbe = oldProbe
    this.counters = {
      bytesFromSource: 0,
      samplesFromTransforms: 0
    }
    try {
      this.setSource(config.source)
      this.setTransforms(config.transforms)
      this.setView(config.view);
      this.setSink(config.sink)
      this.ready = this.init()
      this.ready.catch((err) => { }); // just to avoid error in the console
    } catch (err) {
      console.log('error in DataProbe constructor')
      console.log(err)
      this.handleError(err.toString(), ["INIT"])
    }


  }
  setView(spec) {
    if (!spec) {
      this.view = this.oldProbe.view
    } else {
      console.log('creating new view')
      if (this.view && this.view.clean) {
        const p = this.view.clean();
        // if (p && p.then) await p;
      }
      const viewContainer = document.querySelector("#view");
      viewContainer.innerHTML = "";
      this.view = buildPart(spec, ViewBuilders);
      if (this.view) {
        this.view.onData = async (data) => {
          await this.write(data);
        };
        if (this.view.inject) this.view.inject(viewContainer);
      } else {
        this.handleError(`failed to build view ${spec.type}`, ['VIEW', 'CREATE'])
      }

    }
  }

  setSink(spec) {
    if (spec !== undefined) {
      console.log('creating new sink')
      this.sink = buildPart(spec, SinkBuilders);
    } else {
      this.sink = this.oldProbe ? this.oldProbe.sink : null
    }
  }
  setTransforms(specs) {
    if (specs) {
      console.log('creating new transforms')
      const transforms = new DataTransforms(specs || []);
      this.transforms = transforms;
    } else {
      this.transforms = this.oldProbe.transforms
    }

  }
  setSource(spec) {
    if (spec) {
      console.log('creating new source')

      if (this.oldProbe && this.oldProbe.source) {
        this.oldProbe.stop()
        this.oldProbe.source.onData = null
      }
      const source = buildPart(spec, SourceBuilders);
      this.source = source;
    } else {
      this.source = this.oldProbe.source
    }
    this.source.onData = (d) => {

      this.counters.bytesFromSource += d.byteLength
      if (this.config.bufferDuration) {
        this.parts.push(d);
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          const merged = mergeParts(this.parts);
          this.parts = [];
          this.timeout = null;
          this.handleData(merged);
        }, this.config.bufferDuration);
      } else {
        this.handleData(d);
      }
    };
  }
  async init() {
    let promises = []
    let errors = []
    for (let name of ["source", "transforms", "view", "sink"]) {
      const target = this[name];
      if (target && target.ready) {
        promises.push(target.ready.then(() => {
          log("initialization done", ["OK", name.toUpperCase(), "INIT"]);
        }).catch((err) => {
          errors.push(err)
          this.handleError(err, [name.toUpperCase(), "INIT"]);
        }))
      }
    }
    await Promise.all(promises)
    if (!errors.length) {
      this.setStatus('running')
      if (this.config.bufferDuration) {
        this.parts = [];
        this.timeout = null;
      }
    } else {
      throw errors[0]
    }
  }
  setStatus(s) {

    if (isAllowedTransition(this.status, s)) {
      console.log('from ', this.status, 'to', s)
      this.status = s
      if (s !== 'error')
        this.error = null
      this.eventHandler(s)
    } else {
      // this.stop()
    }

  }
  handleError(err, theme) {
    if (['stopped', 'error'].indexOf(this.status) >= 0) return
    let message = err.toString()
    if (!this.error) {
      notify({ message, theme: ['PROBE', 'ERROR'], level: 'error', detail: err })
    }
    this.error = { message, theme };
    this.setStatus('error')
    this.stop()
  }
  handleData(data) {
    if (this.status === 'running') {
      try { // increase source input byte counter
        if (DEBUG_PROBE.SOURCE)
          log(data, ["DBG", "SOURCE"])
        const items = this.transforms.decode(data);
        this.counters.samplesFromTransforms += items.length
        for (let item of items) {
          let sample = item;
          if (item instanceof ArrayBuffer) {
            const bytes = Array.from(new Uint8Array(item));
            sample = bytes;
          }
          this.feed(this.view, sample, ["VIEW"], ">");
          this.feed(this.sink, sample, ["SINK"]);
        }
      } catch (err) {
        console.error(err)
        this.handleError(err, ["DECODE"]);
      }
    }
  }


  async feed(target, sample, theme = [], tags = "") {
    if (this.error) return;
    if (!target || !target.feed) return;
    try {
      if (DEBUG_PROBE[target])
        log(sample, ["DBG", target])
      const p = target.feed(sample, tags);
      if (p && p.then) await p;
    } catch (err) {
      this.handleError("feeding: " + err.toString(), [...theme]);
    }
  }

  stop() {
    this.source.onData = null
    if (this.source.clean) { this.source.clean(); this.source.stopped = true; }
    if (this.view && this.view.clean) { this.view.clean(); this.view.stopped = true; }
    if (this.sink && this.sink.clean) { this.sink.clean(); this.sink.stopped = true; }
    this.setStatus('stopped')
  }

  async write(data) {
    if (this.status === 'running') {
      const arrayBuffer = this.transforms.encode(data);
      if (arrayBuffer && arrayBuffer.byteLength) {
        this.feed(this.source, arrayBuffer, ["SOURCE"], "<");
        this.feed(this.view, data, ["VIEW"], "<");
      }
    } else {
      const message = "unable to write data while the app is not running"
      notify({ message, theme: ['PROBE', 'WRITE', 'ERROR'], level: 'error' })
    }
  }
}
