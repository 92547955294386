// interface DataSink {
//     feed: (sample: DataSample) => void
// }

import FileSink from "./file";
import InfluxSink from "./influx";

const SinkBuilders = {
    influx(cfg) {
        return new InfluxSink(cfg);
    },
    file(cfg) {
        return new FileSink(cfg);
    }
};

const knownSinks = [
    {
        id: 'influx'
    },
    {
        id: 'file'
    },

]


export { knownSinks, SinkBuilders }