export default class LescanSource {
    constructor(cfg) {
        console.log('creating LE scan source')
        this.config = cfg
        this.onData = () => { }
        this.ready = this.init()
    }

    async init() {
        try {
            if (!navigator.bluetooth || !navigator.bluetooth.requestLEScan) {
                log('Your browser does not support BLE scan.', ['ERROR'])
                log('You should use latest Chrome and enable #enable-experimental-web-platform-features in chrome://flags', [])
                throw 'BLE scan not supported'
            }

            const { bluetooth } = navigator

            console.log('launching LE scan')
            const result = await bluetooth.requestLEScan({
                acceptAllAdvertisements: true,
            })
            console.log('LE scan launched', result)
            bluetooth.addEventListener('advertisementreceived', (evt) => console.log('advertisementreceived', evt))

            console.log('init done')
        } catch (err) {
            console.log('failed to init LescanSource', err.toString())
            throw err
        }



    }

}