import { InfluxDB, Point } from '@influxdata/influxdb-client-browser'

// const { Point, InfluxDB } = require("@influxdata/influxdb-client-browser")

function addField(point, key, value) {
  // console.log('adding field', point, key, value)

  switch (typeof value) {
    // intField ?
    case "boolean":
      point.booleanField(key, value);
      break;
    case "string":
      point.stringField(key, value);
      break;
    case "number":
      point.floatField(key, value);
      break;
    case "object":
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++)
          addField(point, `${key}.${i}`, value[i]);
      } else {
        for (let i in value) {
          addField(point, `${key}.${i}`, value[i]);
        }
      }
      break;
    default:
  }
}

function pointFromLine(line, defaultMeasurement) {
  let measurement, point, tags, fields, timestamp;

  let parts = line
    .split(" ")
    .map((f) => f.trim())
    .filter((f) => f);
  const nParts = parts.length;
  // console.log(parts);
  switch (nParts) {
    case 1:
      tags = "";
      fields = parts[0];
      break;
    case 2:
      tags = parts[0];
      fields = parts[1];
      break;
    case 3:
      tags = parts[0];
      fields = parts[1];
      timestamp = Number(parts[2]);
      break;
    default:
      throw `unable to build influx point from ${nParts} parts`;
  }

  let tolerant = true;
  for (let pair of tags.split(",")) {
    const keyval = pair.split("=");
    if (!point) {
      if (keyval.length === 1 && pair) measurement = keyval[0];
      else measurement = defaultMeasurement;
      point = new Point(measurement);
    }
    if (keyval.length === 2) {
      point.tag(keyval[0], keyval[1]);
    } else {
      if (!tolerant) throw `invalid tag: ` + pair;
    }
    tolerant = false;
  }
  if (Number.isFinite(timestamp)) point.timestamp(timestamp);

  for (let pair of fields.split(",")) {
    const keyval = pair.split("=");
    // console.log("field", pair, keyval);
    if (keyval.length === 2) {
      try {
        addField(point, keyval[0], keyval[1]);
      } catch (err) {
        console.log(`invalid field: ` + pair);
        console.log(err);
      }
    }
  }
  return point;
}

/* config

host
port
database
retention
measurement

*/
export default class InfluxSink {
  constructor(config) {
    this.config = config;
    const client = new InfluxDB({
      url: `http://${config.host || "localhost"}:${config.port || 8086}`,
    });

    const database = config.database || "test";
    const retention = config.retention || "autogen";

    this.client = client;
    this.writeApi = client.getWriteApi("", `${database}/${retention}`, "us", {
      flushInterval: 0,
    });
    this.flushPromise = null;
    this.resolveFlush = null;
    this.rejectFlush = null;
    console.log(client);
    console.log(this.writeApi);
  }

  feed(sample) {
    let point;
    const measurement = this.config.measurement || "dft";
    if (typeof sample === "string") {
      try {
        let line = sample.split(":").pop().trim();
        const firstChar = line.charAt(0);
        if (firstChar.toLowerCase() != firstChar.toUpperCase())
          // only keep if first char is a letter
          point = pointFromLine(line, measurement);
        else {
          throw `should start with a letter`;
        }
      } catch (err) {
        console.log("bad format for influx: ", sample);
        console.log(err);
        return;
      }
    } else {
      point = new Point(measurement);
      for (let key in sample) {
        const value = sample[key];
        if ((this.tag || {})[key]) point.tag(key, value.toString());
        else {
          addField(point, key, value);
        }
      }
    }
    if (!point) return Promise.resolve();

    this.writeApi.writePoint(point);
    if (this.flushPromise) return this.flushPromise;
    else {
      const promise = new Promise((res, rej) => {
        this.flushResolve = res;
        this.flushReject = rej;
      });
      this.flushPromise = promise;
      setTimeout(() => {
        this.flush();
      }, 5000);
    }
  }

  async flush() {
    const { flushPromise, flushResolve, flushReject } = this;
    this.flushPromise = null;
    this.resolveFlush = null;
    this.rejectFlush = null;

    await this.writeApi
      .flush(false)
      .then(() => {
        console.log("flush ok");

        flushResolve();
      })
      .catch((err) => {
        flushReject(err);
      });
  }

  async close() {
    this.writeApi
      .flush(false)
      .catch((err) => {
        console.error("influx write failed: ", err);
      })
      .then(() =>
        this.writeApi.close().catch((e) => {
          console.error("influx close failed: ", err);
        })
      );
  }
}
