import { get, set } from "object-path-immutable"


export function createSampleMap(config) {
    const { expr, input, output } = config
    const fun = (new Function('', `return (${expr})`))()
    try { //validation
        fun({})
        fun(null)
    } catch (err) {
        console.log('invalid filter', err)
    }
    return s => {
        try {
            let current = s
            if (input)
                current = get(current, input)
            const value = fun(current)
            if (output)
                return set(s, output, value)
            else
                return value
        } catch (err) { }
    }
}