import { log } from '../global'

async function exploreServer(server) {
    const services = await server.getPrimaryServices()
    console.log('services discovered')
    for (let service of services) {
        console.log('service', service)
        const characteristics = await service.getCharacteristics()
        for (let characteristic of characteristics) {
            console.log('characteristic', characteristic)
        }
    }
}


// should allow additional services, custom data service/characteristic
export default class BluetoothSource {
    constructor(cfg) {
        console.log('creating bluetooth source')
        this.config = cfg
        this.onData = () => { }
        this.ready = this.init()
    }

    async init() {
        if (!navigator.bluetooth) {
            log('Your browser does not support Web Bluetooth.', ['ERROR'])
            log('You should use latest Chrome and enable #enable-experimental-web-platform-features in chrome://flags', [])
            throw 'web bluetooth not supported'
        }

        const device = await navigator.bluetooth.requestDevice({
            // filters: [{ services: [0xFFE0] }],
            acceptAllDevices: true,
            optionalServices: [0x1800, 0x1801, 0x180A, 0xFFE0,/*0xFFE0*/]
        })

        console.log('got bluetooth device', device)
        this.device = device
        this.device.addEventListener('gattserverdisconnected', (err) => {
            console.log('gatt disconnected', err)
            console.log('should reconnect')
            console.log(this.server)
            this.server = null
            this.characteristic = null
        })
        this.server = await device.gatt.connect()
        console.log('got gatt server', this.server)
        await exploreServer(this.server)
        const service = await this.server.getPrimaryService(0xFFE0)
        const characteristic = (await service.getCharacteristics())[0]
        if (!characteristic)
            throw 'no characteristic found'
        this.characteristic = characteristic
        characteristic.addEventListener('characteristicvaluechanged', (evt) => {
            const dataView = evt.target.value
            const ab = new Uint8Array(dataView.buffer)
            console.log('characteristic value changed', ab)
            if (this.onData) {
                this.onData(ab)
            }
        })

        await characteristic.startNotifications()


        console.log('init done')
        // const encoder = new TextEncoderStream();
        // this.outputDone = encoder.readable.pipeTo(port.writable);
        // this.outputStream = encoder.writable;

    }


    async clean() {

    }

    async feed(arrayBuffer) {
        console.log('writing to c', arrayBuffer)
        if (!this.characteristic)
            throw 'characteristic not ready'
        await this.characteristic.writeValue(arrayBuffer)
    }

}