import { Fragment } from "react";
import * as React from 'react';
import * as ReactDOM from 'react-dom'
import DataProbe from "./data-probe";
import ProbeSummary from "./components/summary";
import notify from "web-ui-blocks/notifications"
import createSimpleTool from 'web-ui-blocks/simple-tool/src'
import builtins from './builtins'

import ProbeControls from "./components/controls";
import { listFiles } from "./sink/file";
import { setLogContainer } from "./global"
import "@fortawesome/fontawesome-free/css/all.css"
import { set } from 'object-path-immutable'

import schema from '../schema'

let probe: DataProbe | null = null;
let store = {
  status: "loading",
  spec: {},
  stats: null,
  files: []
};




const sleep = ms => new Promise((res, rej) => setTimeout(res, ms))

async function refresh() {
  try {
    if (probe) {
      const { counters } = probe
      update(['stats'], counters)
    }
    const files = await listFiles()
    update(['files'], files || [])
  } catch (err) {
    console.log("REFRESH FAILED", err.toString())
    console.error(err)
  }


  await sleep(200)
  refresh()
}

refresh()

const domContainer = document.querySelector("#app");
function update(path, value) {
  // console.log('update', path, value)
  store = set(store, path, value);
  try {
    if (path[0] === 'spec') {
      // const { source, transforms, view, sink } = store.spec
      switch (path[1]) {
        case 'source':
        case 'transforms':
        case 'view':
        case 'sink':
        case undefined:
          probe = new DataProbe(store.spec, handleEvent, probe)
          break;

        default:
          throw 'invalid probe part'
      }
    }
  } catch (err) {
    console.log('failed to update', path, value)
    console.log(err.toString())
  }


  ReactDOM.render(
    <Fragment>
      <ProbeControls store={store} update={update} start={start} stop={stop}></ProbeControls>
      <ProbeSummary
        spec={store.spec}
        stats={store.stats}
        update={async (path, value) => {
          update(["spec", ...path], value);
        }}
      />
    </Fragment>
    ,
    domContainer
  );
  if (path[0] == "status" && value !== store.status)
    switch (value) {
      case 'running':
        return notify({ theme: ['info'], message: "Starts collecting data ..." })
      case 'stopped':
        return notify({ theme: [''], message: "Stops collecting data ..." })
      case 'error':
        return
      default:
        return notify({ theme: [''], message: `App is now ${value}` })
    }
}

const updateConfig = createSimpleTool({
  sources: ['url', 'gdrive'],
  async setup(spec) {
    console.log('using new config', JSON.stringify(spec))
    update(["spec"], spec);

  },
  builtins,
  schema
});





const logContainer = document.querySelector("#logs");
setLogContainer(logContainer);

function handleEvent(evt, content) {
  console.log('event', evt)
  switch (evt) {
    case 'loading':
    case 'running':
    case 'stopped':
    case "error":
      update(["status"], evt)
      update(["error"], probe.error)
      break;
    default:
      console.log('event', evt, content)
      break;
  }
}
function start() {
  console.log('starting ...')
  let spec: any = {}
  const { source } = store.spec as any
  spec.source = source
  // rebuild all parts
  probe = new DataProbe(store.spec, handleEvent, probe);
}


function stop() {
  console.log('stopping ...')
  if (probe) {
    probe.stop()
    probe = null;
  }
}
