// This is a generated file! Please edit source .ksy file and use kaitai-struct-compiler to rebuild

(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['kaitai-struct/KaitaiStream'], factory);
  } else if (typeof module === 'object' && module.exports) {
    module.exports = factory(require('kaitai-struct/KaitaiStream'));
  } else {
    root.Dronisos = factory(root.KaitaiStream);
  }
}(this, function (KaitaiStream) {
var Dronisos = (function() {
  Dronisos.PacketType = Object.freeze({
    LOG: 0,
    LED: 5,
    UWB_POSITION: 17,
    TAG_INFO: 199,
    TAG_CONFIG: 200,
    TAG_TELEMETRY: 201,
    UWB_NOTIFY_RX: 202,
    UWB_DFU_REQUEST: 203,
    UWB_RESET: 204,
    POSITION_ESTIMATE: 205,
    DISTANCE_ESTIMATE: 206,

    0: "LOG",
    5: "LED",
    17: "UWB_POSITION",
    199: "TAG_INFO",
    200: "TAG_CONFIG",
    201: "TAG_TELEMETRY",
    202: "UWB_NOTIFY_RX",
    203: "UWB_DFU_REQUEST",
    204: "UWB_RESET",
    205: "POSITION_ESTIMATE",
    206: "DISTANCE_ESTIMATE",
  });

  function Dronisos(_io, _parent, _root) {
    this._io = _io;
    this._parent = _parent;
    this._root = _root || this;
    this._debug = {};

  }
  Dronisos.prototype._read = function() {
    this._debug.packet = { start: this._io.pos, ioOffset: this._io.byteOffset, enumName: "Dronisos.PacketType" };
    this.packet = this._io.readU1();
    this._debug.packet.end = this._io.pos;
    this._debug.payload = { start: this._io.pos, ioOffset: this._io.byteOffset };
    switch (this.packet) {
    case Dronisos.PacketType.DISTANCE_ESTIMATE:
      this.payload = new DistanceEstimatePayload(this._io, this, this._root, 20);
      this.payload._read();
      break;
    case Dronisos.PacketType.UWB_NOTIFY_RX:
      this.payload = new UwbNotifyRxPayload(this._io, this, this._root);
      this.payload._read();
      break;
    case Dronisos.PacketType.TAG_TELEMETRY:
      this.payload = new TagTelemetryPayload(this._io, this, this._root);
      this.payload._read();
      break;
    default:
      this.payload = new Unknown(this._io, this, this._root);
      this.payload._read();
      break;
    }
    this._debug.payload.end = this._io.pos;
  }

  var Unknown = Dronisos.Unknown = (function() {
    function Unknown(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;
      this._debug = {};

    }
    Unknown.prototype._read = function() {
      this._debug._unnamed0 = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this._unnamed0 = this._io.readU1();
      this._debug._unnamed0.end = this._io.pos;
    }

    return Unknown;
  })();

  var UwbNotifyRxPayload = Dronisos.UwbNotifyRxPayload = (function() {
    function UwbNotifyRxPayload(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;
      this._debug = {};

    }
    UwbNotifyRxPayload.prototype._read = function() {
      this._debug.flags = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.flags = this._io.readU1();
      this._debug.flags.end = this._io.pos;
      this._debug.ctrl = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.ctrl = this._io.readU2le();
      this._debug.ctrl.end = this._io.pos;
      this._debug.bufferSize = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.bufferSize = this._io.readU2le();
      this._debug.bufferSize.end = this._io.pos;
      this._debug.tdw = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.tdw = this._io.readU4le();
      this._debug.tdw.end = this._io.pos;
      this._debug.diagnostics = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.diagnostics = new DwDiagnostics(this._io, this, this._root);
      this.diagnostics._read();
      this._debug.diagnostics.end = this._io.pos;
      this._debug.buffer = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.buffer = this._io.readBytes(this.bufferSize);
      this._debug.buffer.end = this._io.pos;
    }

    return UwbNotifyRxPayload;
  })();

  var TagTelemetryPayload = Dronisos.TagTelemetryPayload = (function() {
    function TagTelemetryPayload(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;
      this._debug = {};

    }
    TagTelemetryPayload.prototype._read = function() {
      this._debug.kind = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.kind = this._io.readU1();
      this._debug.kind.end = this._io.pos;
      this._debug.tick = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.tick = this._io.readU4le();
      this._debug.tick.end = this._io.pos;
      this._debug.txCounter = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.txCounter = this._io.readU2le();
      this._debug.txCounter.end = this._io.pos;
      this._debug.rxCounter = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.rxCounter = this._io.readU2le();
      this._debug.rxCounter.end = this._io.pos;
      this._debug.pollCounter = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.pollCounter = this._io.readU2le();
      this._debug.pollCounter.end = this._io.pos;
      this._debug.twr1Counter = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.twr1Counter = this._io.readU2le();
      this._debug.twr1Counter.end = this._io.pos;
      this._debug.twr2Counter = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.twr2Counter = this._io.readU2le();
      this._debug.twr2Counter.end = this._io.pos;
      this._debug.twriCounter = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.twriCounter = this._io.readU2le();
      this._debug.twriCounter.end = this._io.pos;
      this._debug.responseCounter = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.responseCounter = this._io.readU2le();
      this._debug.responseCounter.end = this._io.pos;
      this._debug.reportCounter = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.reportCounter = this._io.readU2le();
      this._debug.reportCounter.end = this._io.pos;
      this._debug.rxReportCounter = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.rxReportCounter = this._io.readU2le();
      this._debug.rxReportCounter.end = this._io.pos;
      this._debug.txPower = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.txPower = this._io.readU1();
      this._debug.txPower.end = this._io.pos;
      this._debug.pollPeriod = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.pollPeriod = this._io.readU1();
      this._debug.pollPeriod.end = this._io.pos;
      this._debug.randomness = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.randomness = this._io.readU1();
      this._debug.randomness.end = this._io.pos;
    }

    return TagTelemetryPayload;
  })();

  var DistanceEstimatePayload = Dronisos.DistanceEstimatePayload = (function() {
    function DistanceEstimatePayload(_io, _parent, _root, len) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;
      this.len = len;
      this._debug = {};

    }
    DistanceEstimatePayload.prototype._read = function() {
      this._debug.d = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.d = this._io.readU4le();
      this._debug.d.end = this._io.pos;
      this._debug.x = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.x = this._io.readS4le();
      this._debug.x.end = this._io.pos;
      this._debug.y = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.y = this._io.readS4le();
      this._debug.y.end = this._io.pos;
      this._debug.z = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.z = this._io.readS4le();
      this._debug.z.end = this._io.pos;
      this._debug.sigma = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.sigma = this._io.readU2le();
      this._debug.sigma.end = this._io.pos;
      this._debug.serial = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.serial = this._io.readU2le();
      this._debug.serial.end = this._io.pos;
      this._debug.details = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.details = [];
      this._debug.details.arr = [];
      var i = 0;
      while (!this._io.isEof()) {
        this._debug.details.arr[this.details.length] = { start: this._io.pos, ioOffset: this._io.byteOffset };
        this.details.push(this._io.readU4le());
        this._debug.details.arr[this.details.length - 1].end = this._io.pos;
        i++;
      }
      this._debug.details.end = this._io.pos;
    }

    return DistanceEstimatePayload;
  })();

  var DwDiagnostics = Dronisos.DwDiagnostics = (function() {
    function DwDiagnostics(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;
      this._debug = {};

    }
    DwDiagnostics.prototype._read = function() {
      this._debug.maxNoise = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.maxNoise = this._io.readU2le();
      this._debug.maxNoise.end = this._io.pos;
      this._debug.fpA1 = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.fpA1 = this._io.readU2le();
      this._debug.fpA1.end = this._io.pos;
      this._debug.stdNoise = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.stdNoise = this._io.readU2le();
      this._debug.stdNoise.end = this._io.pos;
      this._debug.fpA2 = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.fpA2 = this._io.readU2le();
      this._debug.fpA2.end = this._io.pos;
      this._debug.fpA3 = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.fpA3 = this._io.readU2le();
      this._debug.fpA3.end = this._io.pos;
      this._debug.maxGrowthCir = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.maxGrowthCir = this._io.readU2le();
      this._debug.maxGrowthCir.end = this._io.pos;
      this._debug.rxPreamCount = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.rxPreamCount = this._io.readU2le();
      this._debug.rxPreamCount.end = this._io.pos;
      this._debug.firstPath = { start: this._io.pos, ioOffset: this._io.byteOffset };
      this.firstPath = this._io.readU2le();
      this._debug.firstPath.end = this._io.pos;
    }

    return DwDiagnostics;
  })();

  return Dronisos;
})();
return Dronisos;
}));
