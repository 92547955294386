import { get, set } from "object-path-immutable"
import * as d3 from 'd3'
import { getSampleType, SampleType } from "../data-type"
import { updateLegend } from "./dataviz/legend"
import updateAxis from "./dataviz/axis"
import TimeBins from "./dataviz/timebins"
const BG_COLOR = 'rgb(0,0,0)'
const POINT_WIDTH = 2

const defaultConfig = {
    discontinuity: 10000,
    domain: [-1, 1],
    timespan: 30000,
    property: "value",
    identifier: null
}
function createIdentifier(spec) {
    if (!spec)
        return (s) => null
    if (typeof spec === 'string') {
        return s => {
            let value = s[spec]
            if (value !== undefined) {
                return spec + '=' + (value).toString()
            }
            return ""
        }
    } else if (Array.isArray(spec)) {
        const identifiers = spec.map(s => createIdentifier(s))
        return (s) => identifiers.map(id => id(s).toString()).filter(v => Boolean(v) || Number.isFinite(v)).join(",")
    } else {
        throw "invalid identifier spec: " + spec
    }
}


export default class XYView {
    constructor(cfg) {
        this.config = { ...defaultConfig, ...cfg }



        this.t0 = Date.now()
        this.timestamp = this.t0
        this.samples = []

        this.state = {
            lastPoint: {},
            min: null,
            max: null,
            identities: []
        }
        this.ydomain = this.config.domain
        this.identifier = createIdentifier(this.config.identifier)
        this.fitInterval = setInterval(() => this.fit(), 2000)

        this.binManager = new TimeBins(this.config)

        this.frameRequest = requestAnimationFrame(this.render.bind(this))
    }
    async fit() {

        const xdomain = this.x.domain().map(t => (new Date(t)).getTime())
        const stats = this.binManager.getStats(...xdomain)
        const ydomain = [0, stats.max * 1.5] // TODO : adapt
        if (ydomain[0] !== this.ydomain[0] || ydomain[1] !== this.ydomain[1]) {
            await this.updateCanvas(ydomain)
            this.handleResize(true)
        }
    }
    async updateCanvas(ydomain) {
        const { ctx } = this
        const { canvas } = ctx
        const { width, height } = canvas
        const { clientHeight, clientWidth } = canvas

        const ratio = clientWidth / clientHeight
        const resX = 1000
        const resY = resX / ratio


        const data = ctx.getImageData(0, 0, width, height);
        const image = await createImageBitmap(data)

        canvas.width = resX
        canvas.height = resY

        if (ydomain) {
            const y = resY * (1 - this.ydomain[1] / ydomain[1])
            const h = resY * this.ydomain[1] / ydomain[1];
            this.ctx = canvas.getContext('2d')
            ctx.fillStyle = BG_COLOR
            ctx.fillRect(0, 0, resX, resY)
            ctx.drawImage(image, 0, y, resX, h);
            this.ydomain = ydomain
        } else
            ctx.drawImage(image, 0, 0, resX, resY);
    }
    async handleResize(immediate = false) {
        const legend = () => {
            this.cleanLegend = updateLegend(this.svg, this.color)
        }
        const axis = async () => {
            await this.updateCanvas()
            const { canvas } = this
            const resX = canvas.width
            const resY = canvas.height

            this.svg.attr("viewBox", `0 0 ${resX} ${resY}`)
            const t = Date.now()
            const { timespan } = this.config

            const xscale = d3.scaleTime().domain([t - timespan, t]).range([0, resX])
            const yscale = d3.scaleLinear().domain(this.ydomain).range([resY, 0])
            this.x = xscale
            this.y = yscale
            this.cleanAxis = updateAxis(this.svg, xscale, yscale, { resX, resY })
        }
        if (immediate) {
            await axis()
            await legend()
        } else {
            this.requireUpdate('axis', axis.bind(this))
            this.requireUpdate('legend', legend.bind(this))
        }

    }


    inject(container) {



        const canvas = document.createElement('canvas')

        canvas.style.width = "95%";
        canvas.style.height = "95%";
        container.appendChild(canvas)

        const { clientHeight, clientWidth } = canvas

        const ratio = clientWidth / clientHeight
        const resX = 1000
        const resY = resX / ratio
        canvas.width = resX
        canvas.height = resY


        this.canvas = canvas
        this.ctx = canvas.getContext('2d')
        const { width, height } = canvas
        this.ctx.fillStyle = BG_COLOR
        this.ctx.fillRect(0, 0, width, height)


        container.style.position = 'relative'

        const svg = d3.select(container).append("svg")
            .attr('preserveAspectRatio', 'none')
            .style('overflow', 'visible')
            .style('top', 0)
            .style('left', 0)
            .style("width", "95%")
            .style("height", '95%')
            .style("position", 'absolute')
            .attr("viewBox", `0 0 ${resX} ${resY}`)
        this.svg = svg;


        this.handleResize(true)
        this.resizeObserver = new ResizeObserver(entries => {
            this.handleResize()
        });
        this.resizeObserver.observe(canvas);

        this.updateColorScale()
        this.render()
        // this.ctx.imageSmoothingEnabled = true;
    }

    clean() {
        if (this.frameRequest) {
            if (this.cleanAxis) {
                this.cleanAxis()
            }
            clearInterval(this.fitInterval)
            cancelAnimationFrame(this.frameRequest)
        }
    }

    requireUpdate(tag, fun) {
        const UPDATE_TIMEOUT = 100
        if (!this.updateTimeout)
            this.updateTimeout = setTimeout(() => {
                if (!this.frameRequest)
                    request
                this.updating = true

                for (const k in this.updated || {}) {
                    this.updated[k]()
                }

                this.updateTimeout = null;
                this.updated = null
                this.updating = false
            }, UPDATE_TIMEOUT)
        this.updated = {
            ...(this.updated || {}),
            [tag]: fun
        }
    }

    updateColorScale() {
        const domain = get(this, ['state', "identities"])
        this.color = d3.scaleOrdinal().domain(domain).range(d3.schemeCategory10)
        this.requireUpdate('legend', () => {
            updateLegend(this.svg, this.color)
        })
        // console.log('identities', domain)
    }

    renderPoint(t, value, id) {
        let nPointsAdded = 0

        if (Number.isFinite(value)) {
            const yscale = this.y
            const xscale = this.xscale
            const x = xscale(t)
            const { ctx } = this
            const dx = POINT_WIDTH;
            const identities = this.state.identities
            if (!identities.includes(id)) {
                this.state = set(this.state, ['identities'], [...identities, id])
                this.updateColorScale()
            }
            const y = yscale(value)
            const color = this.color(id)
            ctx.strokeStyle = color
            ctx.lineWidth = 2
            if (this.state.lastPoint[id]) { // WARNING : this does not work with canvas resizing (fit)
                const { xvalue, yvalue } = this.state.lastPoint[id]
                if (t - xvalue < this.config.dicontinuity) { // non hole
                    ctx.beginPath();
                    ctx.moveTo(xscale(xvalue) - dx / 2, yscale(yvalue) - dx / 2)
                    ctx.lineTo(x - dx / 2, y - dx / 2)
                    ctx.stroke()
                }
            }
            ctx.fillStyle = color
            ctx.fillRect(x - dx, y - dx / 2, dx, dx)

            this.state.lastPoint[id] = {
                xvalue: t,
                yvalue: value
            }
            this.binManager.feed(t, value)
            // should check if is highest or lowest
            nPointsAdded++

        } else if (Array.isArray(value)) {
            for (let index = 0; index < value.length; index++)
                nPointsAdded += this.renderPoint(t, value[index], [id, index].filter(i => Boolean(i) || Number.isFinite(i)).join(','))
        }
        return nPointsAdded
    }

    render() {

        try {
            let nPointsAdded = 0;
            const now = Date.now()
            const { timestamp } = this
            const { timespan } = this.config
            const { ctx } = this
            const { canvas } = ctx
            const { width, height } = canvas
            this.shift()

            const samples = (this.samples || [])

            // should be abstracted out of DatagramView
            {
                // WARNING : this changes at each frame
                this.xscale = t => width - (timestamp - now) / timespan
                ctx.fillStyle = 'rgb(255,255,255)'
                for (let sample of samples) {
                    if (typeof sample !== 'object') continue;
                    const t = sample._timestamp || now

                    const value = sample[this.config.property]
                    const id = this.identifier(sample)

                    nPointsAdded += this.renderPoint(t, value, id)

                }
            }

            // if (nPointsAdded)
            //     console.log(Date.now(), 'draw', nPointsAdded, 'points')
            this.samples = []
            if (!this.stopped && !this.error)
                this.frameRequest = requestAnimationFrame(this.render.bind(this))

        } catch (err) {
            this.clean();
            console.log(err)
            this.error = err
        }


    }



    shift() {
        // console.log("shifting by " + px + "px")
        // warning : timing pas précis ...
        const t = Date.now()
        const { timestamp } = this
        const { timespan } = this.config
        const dt = t - timestamp
        const { ctx } = this
        const { canvas } = ctx
        const { width, height } = canvas
        const speed = width / timespan //pixel/ms
        const px = -dt * speed + (this.fractionalPx || 0)

        if (px < -1) {
            const intPx = Math.ceil(px)
            this.fractionalPx = px - intPx
            const { ctx } = this
            const { canvas } = ctx
            const { width, height } = canvas

            const data = ctx.getImageData(0, 0, width, height);
            ctx.fillStyle = BG_COLOR
            ctx.fillRect(width + px, 0, -intPx + POINT_WIDTH, height)
            ctx.putImageData(data, px, 0);
            // requestAnimationFrame(render2)

            this.timestamp += dt
        }
    }


    feed(sample) {
        if (sample === null || sample === undefined) return
        if (!this.error) {
            const _timestamp = Date.now()
            let samples = []
            switch (getSampleType(sample)) {
                case SampleType.ARRAY: samples = [...samples, ...sample.map((value, index) => ({ value, index, }))]
                case SampleType.OBJECT: samples.push(sample)
                case SampleType.NUMBER: samples.push({ value: sample })
                default: break;
            }
            for (let s of samples.map(s => ({ ...s, _timestamp })))
                this.samples.push(s)
        }
    }
}

