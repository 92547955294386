import * as React from 'react';
// timestamp
// influx line protocol
// map
// json

const knownTransforms = [
    {
        id: 'layout',
    },
    {
        id: 'text',
    },
    {
        id: 'hex',
    },
    {
        id: 'lines',
        value: {
            type: "lines",
            eol: "\r\n",
        },
    },
    {
        id: 'sv',
        value: {
            type: 'sv',
            separator: ','
        }
    },

]
export default function Transforms(props) {
    const { update, spec } = props
    const add = (newSpecId) => {
        const newSpec = knownTransforms.filter(s => s.id == newSpecId)[0]
        if (newSpec) {
            const tr = newSpec.value || { type: newSpec.id }
            const trs = [...spec, tr]
            update(trs)
        }
    }
    const del = (index) => {
        const trs = spec.filter((_, i) => i !== index)
        update(trs)
    }
    return (<section className="transforms row">
        <div className="current row">
            {props.spec.map((cfg, i) => (<div className="transform" title={JSON.stringify(cfg, null, 2)} key={i}>{cfg.type}<span className="clickable" onClick={() => del(i)}>ⓧ</span></div>))}

        </div>

        {/* <select value="" className="new" onChange={(e) => {
            add(e.target.value)
            e.target.value = ""
        }
        }>
            <option value="">+transform</option>
            {knownTransforms.map(t => (<option key={t.id} value={t.id}>{t.label || t.id}</option>))}
        </select> */}
    </section >)
}
