module.exports = {
  "name": "ascii from serial",
  "description": "",
  "bufferDuration": 0,
  "source": {
    "type": "serial"
  },
  "transforms": [
    {
      "type": "text"
    },
    {
      "type": "lines",
      "eol": "\r\n"
    }
  ],
  "view": {
    "type": "console"
  },
  "sink": {
    "type": "file",
    "format": "hex",
    "timestamped": true
  }
};