import BluetoothSource, { schema as BluetoothSchema } from "./bluetooth"
import LescanSource from "./lescan";
import SerialSource, { schema as SerialSchema } from "./serial"


const SourceBuilders = {
    random(cfg) {
        return new RandomSource(cfg);
    },
    harmonic(cfg) {
        return new HarmonicSource(cfg);
    },
    bluetooth(cfg) {
        return new BluetoothSource(cfg);
    },
    lescan(cfg) {
        return new LescanSource(cfg);
    },
    serial(cfg) {
        return new SerialSource(cfg);
    },
    device(cfg) {
        return new DeviceSource(cfg);
    },
    default(cfg) {
        return new RandomSource(cfg);
    },
};

const knownSources = [
    {
        id: 'bluetooth',
        // label,
        value: {
            type: 'bluetooth'
        }
    },
    {
        id: 'serial',
        // label,
        value: {
            type: 'serial'
        }
    }, {
        id: 'device'
    },
    {
        id: 'random'
    },
    {
        id: 'harmonic'
    }
]

export {
    SourceBuilders,
    knownSources
}




class HarmonicSource {
    constructor(spec) {
        this.config = spec
        this.interval = setInterval(() => {
            this.gen();
        }, spec.period || 1000);
        const n = this.config.n || 1

        this.phases = Array(n).fill(0).map(() => Math.random() * 2 * Math.PI)
        this.t0 = Date.now()
        setTimeout(() => this.gen());
    }
    gen() {
        const n = this.config.n || 1

        const t = Date.now()
        const buffer = new ArrayBuffer(4 * n);
        const numbers = new Float32Array(buffer);
        for (let i = 0; i < n; i++) {
            const value = Math.sin(2 * Math.PI * (t - this.t0) * (this.config.frequency || 0.1) / (1000) + this.phases[i])
            numbers[i] = value;
        }
        if (this.onData) this.onData(buffer);
    }
    clean() {
        clearInterval(this.interval);
    }
    feed(data) {
        console.log("writing to harmonic source ", data);
    }

}


class RandomSource {
    constructor(spec) {
        this.config = spec
        this.interval = setInterval(() => {
            this.gen();
        }, spec.period || 1000);
        setTimeout(() => this.gen());
        this.t0 = Date.now()
        this.factor = t => 1001
        const { factor } = this.config
        if (factor) {
            try {
                this.factor = (new Function("", `return (${factor})`))();
                const test0 = this.factor(0.0)
                const test1 = this.factor(1.0)
            } catch (err) {
                throw `invalid factor function : ${factor}\n${err.toString()}`
            }

        }
    }
    gen() {
        const n = this.config.n || 1
        const buffer = new ArrayBuffer(4 * n);
        const numbers = new Uint32Array(buffer);
        const time = (Date.now() - this.t0) / 1000
        const factor = this.factor(time)
        for (let i = 0; i < n; i++) {
            const value = Math.floor(Math.random() * factor);
            numbers[i] = value;
        }
        if (this.onData) this.onData(buffer);
    }
    clean() {
        clearInterval(this.interval);
    }
    feed(data) {
        console.log("writing to random source ", data);
    }

}

class DeviceSource {
    constructor() { }
    gen(bytes) {
        const n = bytes.length;
        const buffer = new ArrayBuffer(n);
        const numbers = new Uint8Array(buffer);
        for (let i = 0; i < n; i++) numbers[i] = bytes[i];
        if (this.onData) this.onData(buffer);
    }

    feed(data) {
        const bytes = new Uint8Array(data);
        if (bytes[0] == 0x01) {
            setTimeout(() => this.gen([0x02, 9, 8, 7, 6, 5]));
        }
    }

}
