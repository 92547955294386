import { serializeSample } from "../data-type"

export function encodeFrame(frame, { delimiter }) {
    const ab = new Uint8Array(frame.length + 1)
    let o = 0;
    // ab.set([delimiter], o++)
    ab.set(frame, o); o += frame.length
    ab.set([delimiter], o)
    return ab
}

export function frameDecoder({ delimiter, maxSize }) {
    const MAX_SIZE = maxSize || 256
    let currentFrame = new Uint8Array(MAX_SIZE)
    let currentFrameLength = 0;
    return (sample) => {
        const ab = new Uint8Array(serializeSample(sample))
        let frames = []
        for (const byte of ab) {
            if (byte === delimiter) {
                if (currentFrameLength)
                    frames.push(currentFrame.slice(0, currentFrameLength))
                currentFrameLength = 0;
            } else {
                if (currentFrameLength === MAX_SIZE)
                    throw `frame is too large : max=${MAX_SIZE}`
                currentFrame.set([byte], currentFrameLength)
                currentFrameLength++;
            }
        }
        if (frames.length)
            return frames
    }

}