module.exports = {
    "name": "random points",
    "bufferDuration": 0,
    "source": {
        "type": "random",
        "period": 10,
        "n": 10
    },
    "transforms": [
        {
            "type": "layout"
        }
    ],
    "view": {
        "type": "xy",
        "domain": [
            0,
            1000
        ],
        "discontinuity": 0,
        "property": "values"
    },
    "sink": null
};