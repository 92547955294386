import { log } from '../global'

let inputDone, inputStream, reader;

export default class SerialSource {
  constructor(cfg) {
    this.config = cfg;
    this.onData = () => { };
    this.ready = this.init();
  }

  async init() {
    if (!navigator.serial) {
      log("Your browser does not support Web Serial.", ["ERROR"]);
      log(
        "You should use latest Chrome and enable #enable-experimental-web-platform-features in chrome://flags",
        []
      );
      throw "serial not supported";
    }
    const port = await navigator.serial.requestPort();
    log("got port ");
    await port.open({ baudRate: 115200 });
    // await port.open({ baudRate: 115200, flowControl: 'none', parity: 'none', stopBits: 2});
    this.port = port;
    let decoder = new TextDecoderStream();
    // inputDone = port.readable.pipeTo(decoder.writable);
    // inputStream = decoder.readable;
    // reader = inputStream.getReader();
    reader = port.readable.getReader();
    this.reader = reader;

    const readLoop = async () => {
      while (true) {
        try {
          const { value, done } = await reader.read();
          if (value) {
            this.onData(value.buffer);
            // serial.handle(decoder.decode(value))
          }
          if (done) {
            log("[readLoop] DONE" + done);
            reader.releaseLock();
            break;
          }
        } catch (err) {
          await new Promise((res) => setTimeout(res, 1000));
          console.log(err.toString(), "ERR");
        }
      }
    };

    // const encoder = new TextEncoderStream();
    // this.outputDone = encoder.readable.pipeTo(port.writable);
    // this.outputStream = encoder.writable;

    readLoop();
    this.state = {
      buffer: "",
      port,
    };
  }

  // voir https://codelabs.developers.google.com/codelabs/web-serial
  // cf TransformStream
  async createSerial(port) {
    return port;
  }

  async clean() {
    if (this.reader) {
      await this.reader.cancel();
    }

    if (this.port) await this.port.close();
    this.port = null;
  }

  feed(arrayBuffer) {
    console.log("sending array buffer to serial", arrayBuffer);
    const writer = this.port.writable.getWriter();
    writer.write(arrayBuffer);
    writer.releaseLock();
  }
}

const LINE_SEPARATOR = "\r\n";
