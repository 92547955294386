

export function createSampleFilter(config) {
    const { expr } = config
    const fun = (new Function('', `return (${expr})`))()
    try { //validation
        fun({})
        fun(null)
    } catch (err) {
        console.log('invalid filter', err.toString())
    }
    return s => {
        try {
            return fun(s)
        } catch (err) { }
    }
}