import DatagramView, { schema as DatagramSchema } from './datagram'
import ConsoleView, { schema as ConsoleSchema } from './console'
import XYView, { schema as XYSchema } from './xy'
import HistogramView, { schema as HistogramSchema } from './histograms';
const ViewBuilders = {
    histograms(cfg) {
        return new HistogramView(cfg);
    },
    console(cfg) {
        return new ConsoleView(cfg);
    },
    datagram(cfg) {
        return new DatagramView(cfg)
    },
    xy(cfg) {
        return new XYView(cfg)
    },
    default(cfg) {
        return null
    },
};
const knownViews = [
    {
        id: "console"
    },
    {
        id: "histograms"
    },
    {
        id: "datagram"
    },
    {
        id: "xy"
    },
];



export { ViewBuilders, knownViews }