module.exports = {
    "name": "periodic curve",
    "bufferDuration": 0,
    "source": {
        "type": "harmonic",
        "period": 10,
        "frequency": 0.05,
        "n": 100
    },
    "transforms": [
        {
            "type": "layout",
            "types": [
                "f32"
            ]
        }
    ],
    "view": {
        "type": "xy",
        "discontinuity": 0,
        "property": "values"
    },
    "sink": null
};