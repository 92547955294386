import { get, set, clear, entries, keys } from 'idb-keyval'
import { serializeSample, toHexString } from '../data-type'

export async function clearFiles() {
    await clear()
}
export async function listIds() {
    const ids = await keys()
    return ids
}

export async function listFiles() {
    const allEntries = await entries()
    return allEntries.map(([id, blob]) => ({
        name: id,
        size: blob.size,
        type: blob.type
    }))
}

export async function setFile(id, blob) {

}

export async function getFile(id) {
    return get(id)

}

export default class FileSink {
    constructor(config) {
        console.log('new file sink', config)
        this.config = config
        this.parts = []
        this.currentPart = null
        this.lastWrite = this.init()
        this.nextWrite = null
    }
    async init() {
        const { append, name, format } = this.config
        console.log('init file sink', this.config)
        this.name = name || 'default'
        this.format = format || 'ndjson'
        if (append) {
            try {
                const lastBlob = await getFile(this.name)
                const ab = await lastBlob.arrayBuffer()
                this.parts.push(ab)
            } catch (err) {
                console.log('failed to fetch previous file', this.name)
            }
        }

    }
    feed(sample) {
        const sep = this.config.separator || "\n"
        let prefix = ""
        if (this.config.timestamped)
            prefix = new Date().toISOString() + " : "
        switch (this.format) {
            case 'raw':
                const raw = serializeSample(sample)
                if (this.currentPart) {
                    const mergedArray = new Uint8Array(this.currentPart.length + raw.length);
                    mergedArray.set(this.currentPart);
                    mergedArray.set(raw, this.currentPart.length);
                    this.currentPart = mergedArray
                } else {
                    this.currentPart = raw
                }
            case 'hex':
                {
                    const raw = serializeSample(sample)
                    const sep = this.config.separator || "\n"
                    const bytes = new Uint8Array(raw.length)
                    if (typeof raw === 'string')
                        for (let i = 0; i < raw.length; i++)
                            bytes[i] = raw.charCodeAt(i)
                    else {
                        bytes.set(raw, 0)
                    }
                    let line = toHexString(bytes)
                    this.currentPart = (this.currentPart || "") + prefix + line + sep
                }
                break;
            case 'text':
                {
                    const line = sample.trim().toString()
                    console.log('into file', JSON.stringify(line))
                    this.currentPart = (this.currentPart || "") + prefix + line + sep
                }
                break;
            case "ndjson":
                const line = JSON.stringify(sample)
                this.currentPart = (this.currentPart || "") + prefix + line + sep
                break;
            default:
                throw `invalid format: ` + this.format
        }
        if (!this.nextWrite) {
            this.nextWrite = setTimeout(this.flush.bind(this), 1000);
        }
    }
    async flush() {
        await this.lastWrite
        this.parts.push(this.currentPart)
        this.currentPart = null
        this.nextWrite = null
        const blob = new Blob(this.parts, { type: 'text/plain' })
        this.lastWrite = set(this.name, blob).then((...result) => {
            console.log('flushed file', this.name)
        }).catch(err => {
            console.log("failed to flush", err.toString())
        });
    }
}