export enum SampleType {
  STRING = "string",
  BUFFER = "buffer",
  NUMBER = "number",
  ARRAY = "array",
  OBJECT = "object",
  INVALID = "invalid"
}

export function getSampleType(sample): SampleType {
  switch (typeof sample) {
    case "string": return SampleType.STRING;
    case 'object': {
      if (sample instanceof ArrayBuffer || sample.buffer instanceof ArrayBuffer)
        return SampleType.BUFFER
      else if (Array.isArray(sample))
        return SampleType.ARRAY
      else
        return SampleType.OBJECT
    }
    case 'number':
      return SampleType.NUMBER
    default:
      return SampleType.INVALID
  }
}

export function serializeSample(sample) {
  const type = getSampleType(sample)
  switch (type) {
    case SampleType.STRING: return sample.toString()
    case SampleType.BUFFER: return sample
    case SampleType.NUMBER: return sample
    case SampleType.ARRAY: return sample.map(s=>serializeSample(s))
    case SampleType.OBJECT: return JSON.stringify(sample) + "\n"
    default: return
  }
}

export function stringifySample(sample) {
  const type = getSampleType(sample)
  switch (type) {
    case SampleType.STRING: return sample.toString();
    case SampleType.ARRAY:
    case SampleType.OBJECT: return JSON.stringify(sample, (k, v) => {
      if (v instanceof ArrayBuffer || ArrayBuffer.isView(v)) {
        return toHexString(Array.from(v))
      }
      return v
    });
    case SampleType.BUFFER:
      if (sample instanceof ArrayBuffer)
        sample = new Uint8Array(sample)
      return toHexString(sample);
    case SampleType.INVALID:
    default:
      return ""
  }
}

export const fromHexString = (hexString) => {
  if (!hexString) return;
  hexString = hexString.split("0x").pop().trim()
  const numbers = hexString
    .match(/.{1,2}/g)
    .map((byte) => parseInt(byte, 16));
  if (numbers.filter((n) => Number.isNaN(n)).length) {
    throw "invalid hex string: " + hexString
  }
  return new Uint8Array(numbers);
};

export const toHexString = (bytes) =>
  bytes.reduce(
    (str, byte) => str + byte.toString(16).padStart(2, "0"),
    ""
  );