


export default class HistogramView {
    constructor(cfg) {
        this.config = cfg



        this.t0 = Date.now()
        this.timestamp = this.t0
        this.samples = []


        this.frameRequest = requestAnimationFrame(this.render.bind(this))
    }

    inject(container) {
        const canvas = document.createElement('canvas')
        canvas.style.width = "100%";
        canvas.style.height = "95%";
        container.appendChild(canvas)
        this.canvas = canvas
        this.ctx = canvas.getContext('2d')
        const { width, height } = canvas
        this.ctx.fillStyle = 'rgb(0,0,0)'
        this.ctx.fillRect(0, 0, width, height)

        this.render()
    }

    clean() {
        if (this.frameRequest) {
            cancelAnimationFrame(this.frameRequest)
        }
    }

    render() {
        const t = Date.now()
        const { ctx } = this
        if (!ctx) {
            return
        }
        const samples = this.samples
        if (samples && samples.length) {
            // console.log('rendering', this.samples)
            const { width, height } = ctx.canvas
            ctx.fillStyle = 'rgb(0,0,0)'
            ctx.fillRect(0, 0, width, height)
            const yscale = v => height * (1 - v / 1024)
            ctx.fillStyle = 'rgb(255,255,255)'
            for (let sample of samples) {
                const y = yscale(sample.timestamp)
                const n = sample.values.length
                const dx = width / n
                sample.values.forEach((value, index) => {
                    const y = yscale(value)
                    const x = (index) * dx
                    ctx.fillRect(x, y, dx, height - y)
                })
            }
        }


        // frameIndex = (frameIndex + 1) % 10
        // if (!frameIndex) {
        //     const first = samples[0]
        //     if (first)
        //         log('dt=' + (t - first.timestamp).toFixed(0) + '  ' + first.line, ["serial"])
        // }
        this.samples = []
        this.timestamp = t
        this.frameRequest = requestAnimationFrame(this.render.bind(this))
    }

    feed(sample) {
        if (Number.isFinite(sample.values[0])) {// valid sample ?
            if (!this.error) {
                this.samples.push(sample)
            }
        }
    }
}



