import * as d3 from "d3"

export default function updateAxis(elt, xscale, yscale) {
    const t = Date.now()
    const svg = elt
    const viewbox = svg.attr('viewBox')
    const [x0, y0, x1, y1] = viewbox.split(" ").map(item => Number(item)).filter(Number.isFinite)
    const resX = x1 - x0
    const resY = y1 - y0
    svg.selectAll(".yaxis").remove()
    svg.selectAll(".xaxis").remove()


    const yAxis = d3.axisRight(yscale)
    svg.append("g").attr('transform', `translate(${resX}, 0)`).attr("class", "yaxis").call(yAxis)


    const time_extent = xscale.domain()
    const timespan = time_extent[1] - time_extent[0]
    const TRANSITION_PERIOD = 5000
    const xAxis = d3.axisBottom(xscale).tickFormat(d3.timeFormat("%H:%M:%S"))
    const g = svg.append("g").attr('transform', `translate(0, ${resY})`).attr("class", "xaxis")
    g.call(xAxis)


    const xAxistransition = (period) => {
        const t = Date.now()
        xscale.domain([t - timespan, t].map(t => t + period))
        g.transition().duration(period).ease(d3.easeLinear).call(xAxis)
    }
    xAxistransition(TRANSITION_PERIOD)
    if (elt.axisTransitionInterval)
        clearInterval(elt.axisTransitionInterval)
    elt.axisTransitionInterval = setInterval(() => {
        xAxistransition(TRANSITION_PERIOD)
    }, TRANSITION_PERIOD)

    return () => {
        xAxistransition(0)
        clearInterval(elt.axisTransitionInterval)
    }
    // this.x = x
    // this.y = y
}