import KaitaiStream from "kaitai-struct/KaitaiStream";
import { toHexString } from "../../data-type";
import * as Dronisos from "./Dronisos";

function isKaiTaiStruct(obj) {
    return typeof obj === 'object' && obj._debug && obj._io
}
function cleanKaitaiObject(obj) {
    if (!isKaiTaiStruct(obj))
        return obj
    let result = {}

    for (const k in obj) {
        if (!k.startsWith('_')) {
            result[k] = cleanKaitaiObject(obj[k])
        }
    }
    return result
}

const dronisosPacket = new Dronisos.default()
function transformerFromKaitai(kaitai) {
    // var arrayBuffer = oReq.response;
    // var parsedElf = new Elf(new KaitaiStream(arrayBuffer));
    // document.getElementById("dump").textContent =
    //     "machineType: " + Elf.Machine[parsedElf.header.machine] + "\n" +
    //     "program headers:\n" + parsedElf.header.sectionHeaders.map(x => " - " + x.name).join("\n");
    return {
        decode: (sample) => {
            const packet = new kaitai.default(new KaitaiStream(sample))
            let decoded
            try {
                packet._read()

                decoded = cleanKaitaiObject(packet.payload)
                decoded.packetType = toHexString([packet.packet]);
            } catch (err) {
                console.log(err.toString())
            }
            return decoded
        }
    }
}
export default {
    dronisos: transformerFromKaitai(Dronisos)
}