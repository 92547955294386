import { fromHexString } from '../data-type';
import { log } from '../global'



let preferredConverterName = ""
const converters = {
  buffer: fromHexString,
  object: JSON.parse,
  number: v => {
    const n = Number(v)
    if (Number.isFinite(n))
      return n
  },
  default: (value) => value
}


export default class ConsoleView {
  constructor() {
    this.samples = [];
  }

  inject(container) {
    this.container = container;
    console.log("preferredConverterName", preferredConverterName)
    container.innerHTML = `
        <div class="console" style="display: flex;flex-direction: column;height: 100%;">
            <div class="lines" style="flex: 1;overflow: auto;"></div>
            <div class="user-input row">
              <select>
                <option>string</option>
                <option>buffer</option>
                <option>object</option>
                <option>number</option>
              </select>
              <input type="text" style="flex:1;margin:0;" placeholder="enter text">
            </div>
        </div>
        `;
    const select = container.querySelector('select')
    select.value = preferredConverterName
    select.addEventListener('change', (ev) => {
      console.log('type changed', ev.target.value)
      preferredConverterName = ev.target.value.toLowerCase()
    })
    container.querySelector("input").addEventListener("keyup", async (evt) => {
      try {
        if (evt.key == "Enter") {
          const { value } = evt.target;
          const converter = converters[preferredConverterName || 'default'];
          const sample = converter(value)
          if (this.onData) this.onData(sample);
          evt.target.value = "";
        }
      } catch (err) {
        console.log(err);
      }
    });
  }
  feed(sample, tags) {
    const element = this.container.querySelector(".lines")
    let atBottom = (element.scrollTop + element.clientHeight) + 1 > element.scrollHeight
    log(sample, tags, element, {
      timestamp: false,
    });
    if (atBottom)
      element.scrollTop = element.scrollHeight
  }
}
