import { getSampleType, stringifySample } from "./data-type";
let _global = {
  logContainer: null,
  logOptions: {
    timestamp: true
  },
  t0: 0
};

export function setLogContainer(l) {
  _global.logContainer = l;
  log("app launched", "INIT");
}

export function toggleLogContainer() {
  console.log("logContainer", _global.logContainer)
  if (_global.logContainer) {
    const previous = _global.logContainer.style['display']
    if (previous === 'none')
      _global.logContainer.style['display'] = 'unset';
    else
      _global.logContainer.style['display'] = 'none';

  }
}

export const DEBUG_PROBE = {
  SOURCE: false,
  TRANSFORMS: false,
  VIEW: false,
  SINK: false,
}

export function setDebug(key, value) {
  if (DEBUG_PROBE[key] === undefined)
    throw `invalid debug key : ${key}`
  DEBUG_PROBE[key] = value
}

export function log(message, theme, container, options = {}) {
  if (!container) {
    container = _global.logContainer
    options = { ..._global.logOptions, ...options }
  };
  if (Array.isArray(message))
    return message.map(m => log(m, theme, container, options))


  const type = getSampleType(message)
  message = stringifySample(message);

  if (theme && !Array.isArray(theme)) theme = [theme];

  let line = "";
  if (options.timestamp) {
    if (!_global.t0)
      _global.t0 = Date.now()
    const t = options.timestamp !== true ? options.timestamp : (Date.now() - _global.t0);

    line += "" + (t / 1000).toFixed(3).padStart(7) + "     ";
  }
  line += (theme ? theme.join(".") : "").padEnd(20) + (type ? `(${type}) ` : "") + message;


  if (container) {
    if (!container.t0) {
      container.t0 = Date.now();
      container.lines = [];
      container.maxLines = 100;
    }
    let { lines, t0, maxLines } = container;

    lines.push(line);
    if (lines.length > maxLines) {
      lines = lines.slice(lines.length - maxLines);
    }

    const bottom =
      container.scrollTop === container.scrollHeight - container.offsetHeight;


    container.lines = lines;

    container.innerHTML = lines.map((l) => `<pre>${l}</pre>`).join("");
    if (bottom) {
      container.scrollTop = container.scrollHeight - container.offsetHeight;
    }
  } else {
    console.debug(line)
  }

}
