module.exports = {
  "$schema": "http://json-schema.org/draft-07/schema",
  "$id": "https://4np0t3t.gitlab.io/data-probe/schema.json",
  "type": "object",
  "title": "The configuration for a Data Probe",
  "description": "The root schema comprises the entire JSON document.",
  "properties": {
    "bufferDuration": {
      "type": "integer",
      "default": 0
    },
    "source": {
      "oneOf": [
        {
          "type": "object",
          "properties": {
            "type": {
              "enum": [
                "harmonic"
              ]
            },
            "period": {
              "type": "number",
              "description": "period in ms between 2 samples"
            },
            "frequency": {
              "type": "number"
            },
            "n": {
              "type": "number"
            }
          },
          "additionalProperties": false
        },
        {
          "type": "object",
          "properties": {
            "type": {
              "enum": [
                "bluetooth"
              ]
            }
          }
        },
        {
          "type": "object",
          "properties": {
            "type": {
              "enum": [
                "lescan"
              ]
            }
          },
          "additionalProperties": false
        },
        {
          "type": "object",
          "properties": {
            "type": {
              "enum": [
                "serial"
              ]
            }
          }
        },
        {
          "type": "object",
          "properties": {
            "type": {
              "enum": [
                "random"
              ]
            },
            "factor": {
              "type": "string",
              "description": "a function applied after a uniform sampling"
            },
            "period": {
              "type": "number",
              "description": "period in ms between 2 samples"
            },
            "n": {
              "type": "number"
            }
          },
          "additionalProperties": false
        },
        {
          "type": "object",
          "properties": {
            "type": {
              "enum": [
                "device"
              ]
            }
          },
          "additionalProperties": false
        }
      ],
      "type": "object",
      "required": [
        "type"
      ]
    },
    "transforms": {
      "$id": "#/properties/transforms",
      "type": "array",
      "items": {
        "type": "object",
        "anyOf": [
          {
            "type": "object",
            "properties": {
              "type": {
                "enum": [
                  "text"
                ]
              }
            },
            "additionalProperties": false
          },
          {
            "type": "object",
            "properties": {
              "type": {
                "enum": [
                  "hex"
                ]
              }
            },
            "additionalProperties": false
          },
          {
            "type": "object",
            "properties": {
              "type": {
                "enum": [
                  "frames"
                ]
              },
              "delimiter": {
                "type": "number"
              }
            },
            "additionalProperties": false
          },
          {
            "type": "object",
            "properties": {
              "type": {
                "enum": [
                  "cobs"
                ]
              },
              "symbol": {
                "type": "number"
              }
            },
            "additionalProperties": false
          },
          {
            "type": "object",
            "properties": {
              "type": {
                "enum": [
                  "crc"
                ]
              }
            },
            "additionalProperties": false
          },
          {
            "type": "object",
            "properties": {
              "type": {
                "enum": [
                  "lines"
                ]
              },
              "eol": {
                "type": "string"
              }
            },
            "additionalProperties": false
          },
          {
            "type": "object",
            "properties": {
              "type": {
                "enum": [
                  "layout"
                ]
              },
              "types": {
                "type": "array",
                "items": {
                  "enum": [
                    "f32",
                    "u32"
                  ]
                }
              }
            },
            "additionalProperties": false
          },
          {
            "type": "object",
            "properties": {
              "type": {
                "enum": [
                  "kaitai"
                ]
              },
              "spec": {
                "type": "string",
                "description": "url to a kaitai spec or identifier of a builtin protocol (eg : dronisos)"
              }
            },
            "additionalProperties": false
          },
          {
            "type": "object",
            "properties": {
              "type": {
                "enum": [
                  "map"
                ]
              },
              "input": {
                "type": "array",
                "items": {
                  "type": "string"
                }
              },
              "output": {
                "type": "array",
                "items": {
                  "type": "string"
                }
              },
              "expr": {
                "type": "string",
                "description": "function applied to map inputs to outputs"
              }
            },
            "additionalProperties": false
          }
        ],
        "required": [
          "type"
        ]
      }
    },
    "view": {
      "type": "object",
      "required": [
        "type"
      ],
      "oneOf": [
        {
          "properties": {
            "type": {
              "enum": [
                "xy"
              ]
            },
            "domain": {
              "type": "array",
              "minItems": 2,
              "maxItems": 2,
              "items": {
                "type": "number"
              }
            },
            "discontinuity": {
              "type": "number"
            },
            "property": {
              "type": "string"
            },
            "identifier": {
              "description": "if those properties are the same for 2 samples, the points will be in the same serie",
              "type": "array",
              "items": {
                "type": "string"
              }
            }
          },
          "additionalProperties": false
        },
        {
          "properties": {
            "type": {
              "enum": [
                "datagram"
              ]
            }
          },
          "additionalProperties": false
        },
        {
          "properties": {
            "type": {
              "enum": [
                "console"
              ]
            }
          },
          "additionalProperties": false
        },
        {
          "properties": {
            "type": {
              "enum": [
                "histograms"
              ]
            }
          },
          "additionalProperties": false
        }
      ]
    },
    "sink": {
      "anyOf": [
        {
          "type": "null"
        },
        {
          "type": "object",
          "anyOf": [
            {
              "properties": {
                "type": {
                  "type": "string",
                  "enum": [
                    "influx"
                  ]
                },
                "database": {
                  "type": "string"
                },
                "measurement": {
                  "type": "string"
                },
                "host": {
                  "type": "string"
                },
                "port": {
                  "type": "number"
                },
                "retention": {
                  "type": "string"
                }
              },
              "additionalProperties": false
            },
            {
              "type": "null"
            },
            {
              "properties": {
                "type": {
                  "enum": [
                    "file"
                  ]
                },
                "name": {
                  "type": "string"
                },
                "format": {
                  "enum": [
                    "raw",
                    "text",
                    "hex",
                    "binary",
                    "ndjson",
                    "influx-line"
                  ]
                },
                "timestamped": {
                  "type": "boolean"
                },
                "separator": {
                  "type": "string"
                }
              },
              "additionalProperties": false
            }
          ],
          "required": [
            "type"
          ]
        }
      ]
    }
  },
  "required": [
    "source",
    "view"
  ],
  "additionalProperties": true
}
;