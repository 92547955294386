
import randomPoints from '../specs/random-points.json'
import binarySerial from '../specs/binary-serial.json'
import asciiSerial from '../specs/ascii-serial.json'
import harmonic from '../specs/harmonic.json'
import divergingRandomPoints from "../specs/diverging-points.json"
import { clear, createStore, entries, get, set } from 'idb-keyval'
const sample = {
    name: 'abc',
    _date: 56789,
    _builtin: true
}



const uwbSpec1 = {

    "name": "uwb",
    "description": "",
    "bufferDuration": 0,
    "source": {
        "type": "serial"
    },
    "transforms": [
        {
            "type": "text"
        },
        {
            "type": "lines",
            "eol": "\r\n"
        },
        {
            "type": "hex-string"
        },
        {
            "type": "kaitai",
            "spec": "dronisos"
        }
    ],
    "view": {
        "type": "console"
    },
    // "view": {
    //     "type": "xy",
    //     "property": "d",
    //     "domain": [0, 5000]
    // },
    "sink": {
        "type": "file",
        "format": "raw"
    },
    "$schema": "https://probe.beap.ovh/schema.json"

}

const uwbPlot = {
    "name": "uwb plot",
    "description": "",
    "bufferDuration": 0,
    "source": {
        "type": "serial"
    },
    "transforms": [
        {
            "type": "frames",
            "delimiter": 0
        },
        {
            "type": "cobs",
            "symbol": 0
        },
        {
            "type": "crc"
        },
        {
            "type": "kaitai",
            "spec": "dronisos"
        },
        {
            "type": "map",
            "input": ["serial"],
            "output": ["serial"],
            "expr": "s=>(s || '').toString(16)"
        }
    ],
    // "view": {
    //     "type": "console"
    // },
    "view": {
        "type": "xy",
        "property": "d",
        "domain": [0, 20000],
        "identifier": ["x", "y", "z", "sigma", "serial"]
    },
    "sink": {
        "type": "file",
        "format": "ndjson"
    },
    "$schema": "https://probe.beap.ovh/schema.json"
}
const uwbConsole = {
    "name": "uwb console",
    "description": "",
    "bufferDuration": 0,
    "source": {
        "type": "serial"
    },
    "transforms": [
        {
            "type": "frames",
            "delimiter": 0
        },
        {
            "type": "cobs",
            "symbol": 0
        },
        {
            "type": "crc"
        },
        {
            "type": "kaitai",
            "spec": "dronisos"
        }
    ],
    "view": {
        "type": "console"
    },
    "sink": {
        "type": "file",
        "format": "ndjson",
        "timestamped": true
    },
    "$schema": "https://probe.beap.ovh/schema.json"
}

const bluetoothScanner = {
    "name": "bluetooth scanner",
    "description": "",
    "bufferDuration": 0,
    "source": {
        "type": "lescan"
    },
    "transforms": [
    ],
    "view": {
        "type": "console"
    },
    "sink": null,
    "$schema": "https://probe.beap.ovh/schema.json"
}

const bluetoothConsole = {
    "name": "bluetooth console",
    "description": "",
    "bufferDuration": 0,
    "source": {
        "type": "bluetooth"
    },
    "transforms": [
    ],
    "view": {
        "type": "console"
    },
    "sink": null,
    "$schema": "https://probe.beap.ovh/schema.json"
}

export default [
    bluetoothConsole,
    asciiSerial,

    uwbConsole,

    binarySerial,

    divergingRandomPoints,
    uwbPlot, // plot
    randomPoints,
    harmonic,
    bluetoothScanner,

].map((json: any) => ({
    name: json.name || 'unknown',
    source: 'builtin',
    raw: new Blob([JSON.stringify(json)], { type: 'application/json' }),
}))
