import * as d3 from "d3"

// THIS DOES NOT WORK IF BUILT TWICE
export function updateLegend(svg, scale) {
    svg.selectAll("g.legend").remove()
    const legend = svg.append("g").attr("class", "legend")


    // select the svg area
    if (!scale)
        return;
    // create a list of keys
    var domain = scale.domain();// ["Mister A", "Brigitte", "Eleonore", "Another friend", "Batman"]
    const maxHeight = 300
    const hPerItem = 30
    const keys = domain.filter((_, index) => index * hPerItem < maxHeight)
    if (keys.length < domain.length)
        keys.push("...")

    if (!keys.length)
        return;

    const PADDING = 10
    const OFFSET = 20

    const render = () => {

        legend.selectAll("circle").remove()
        legend.selectAll("text").remove()


        legend.selectAll("mydots")
            .data(keys.filter(k => k !== "..."))
            .enter()
            .append("circle")
            .attr("cx", OFFSET + PADDING)
            .attr("cy", function (d, i) { return OFFSET + PADDING + i * hPerItem }) // 100 is where the first dot appears. 25 is the distance between dots
            .attr("r", 7)
            .style("fill", function (d) { return scale(d) })

        // Add one dot in the legend for each name.
        legend.selectAll("mylabels")
            .data(keys)
            .enter()
            .append("text")
            .attr("x", OFFSET + 3 * PADDING)
            .attr("y", function (d, i) { return OFFSET + PADDING + i * hPerItem }) // 100 is where the first dot appears. 25 is the distance between dots
            .style("fill", (d) => d === "..." ? "white" : scale(d))
            .text(function (d) { return d })
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")
    }

    // Add one dot in the legend for each name.
    render()
    const bbox = legend.node().getBBox()
    legend.selectAll("rect").remove()
    legend
        // .selectAll("background")
        // .enter()
        .append("rect")
        .style("fill", "#111")
        .attr("x", bbox.x - PADDING)
        .attr("y", bbox.y - PADDING)
        .attr("rx", PADDING)
        .attr("ry", PADDING)
        .attr("width", bbox.width + 2 * PADDING)
        .attr("height", bbox.height + 2 * PADDING)

    render()
}