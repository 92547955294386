const createBin = (x0, x1) => {
    return {
        x0,
        x1,
        n: 0,
        min: null,
        max: null,
        sum: 0,
        sum2: 0
    }
}


function getStats(bins) {
    if (!bins.length)
        return {
            min: 0,
            max: 1
        }
    let { min, max } = bins[0]
    for (const bin of bins) {
        min = Math.min(min, bin.min)
        max = Math.max(max, bin.max)
    }
    return {
        min,
        max,
    }
}

const intersect = ([min0, max0], [min1, max1]) => {
    const ordered = [{ value: min0, interval: 0 }, { value: max0, interval: 0 }, { value: min1, interval: 1 }, { value: max1, interval: 1 }]
    ordered.sort((a, b) => a.value < b.value ? 1 : -1)
    if (ordered[0].interval === ordered[1].interval)
        return false
    return true
}
const defaultConfig = {
    binWidth: 5000,
}
export default class TimeBins {
    constructor(config = {}) {
        this.config = { ...defaultConfig, ...config }
        this.bins = []
    }
    feed(x, y) {
        const bin = this.getBin(x)
        bin.n++;
        if (Number.isFinite(bin.min)) {
            bin.min = Math.min(bin.min, y)
            bin.max = Math.max(bin.max, y)
        } else {
            bin.min = y
            bin.max = y
        }
        bin.sum += y
        bin.sum2 += y * y
    }

    getBin(x) {
        let bin = this.bins.find(b => b.x0 <= x && b.x1 > x)
        if (!bin) {
            const { binWidth } = this.config
            const x0 = Math.floor(x / binWidth) * binWidth
            const x1 = x0 + binWidth
            bin = createBin(x0, x1)
            this.bins.push(bin)
        }
        return bin;
    }

    compact(time) {
        const totalWidth = this.config.totalWidth || this.config.binWidth * 10
        const { timespan } = this.config
        // TODO : remove old bins
    }
    getStats(x0, x1) {
        const bins = this.bins.filter(b => intersect([b.x0, b.x1], [x0, x1]))
        console.log('getting stats from ', bins.length, 'bins')
        return getStats(bins)
    }
}